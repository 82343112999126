import config from './sxjgz'

const global = {
  CopyRight:
    'Copyright @2020-2025 河南安洛云信息科技有限公司 技术服务：400 6379 003',
  // CopyRight: "©·2020-2022 河南得贤人力资源服务有限公司版权所有权",
  Record: '备案号：豫ICP备17050318号',
}

export default Object.assign(global, config)
// document.querySelector("title").innerText = config.objectName;
