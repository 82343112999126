export default {
  area: "嵩县九泉镇石场村数智管理平台",
  objectName: "",
  //url
  hostUrl: 'https://yyjp.zhsq.cloud',
  apiHttpsUrl: 'https://sxjgzapi.anluoyun.cn/',
  apiRequestUrl: 'https://sxjgzapi.anluoyun.cn/api',
  apiUploadUrl: 'https://sxjgzapi.anluoyun.cn/api/UploadFile/UploadImgOrFile',
  apiUploadFiles: 'https://sxjgzapi.anluoyun.cn/api/UploadFile/UploadFiles',
  apiUploadvideo: 'https://sxjgzapi.anluoyun.cn/api/UploadFile/UploadQiniuVideo',
}